<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
.bg-dark {
  background-color: #e6e6e6 !important;
}
</style>
<template>
  <div class="w-full ml-auto mr-auto">
    <vs-divider style="width: 100%"><b>Debit Note Apply Schema</b></vs-divider>
    <vs-table v-if="schemaLines.length > 0" :data="schemaLines">
      <template slot="thead">
        <vs-th> # </vs-th>
        <vs-th>
          {{ generateTypeName() }}
        </vs-th>
        <vs-th> {{ generateTypeName() }} Remaining Before </vs-th>
        <vs-th> Debit Note </vs-th>
        <vs-th> Debit Note Remaining Before </vs-th>
        <vs-th> Paid </vs-th>
        <vs-th> {{ generateTypeName() }} Remaining After </vs-th>
        <vs-th> Debit Note Remaining After </vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="indextr + 1">
            {{ indextr + 1 }}
          </vs-td>

          <vs-td
            :data="
              tr.PurchaseInvoiceID
                ? tr.PurchaseInvoiceCode
                : tr.CreditNoteSupplierCode
            "
          >
            {{
              tr.PurchaseInvoiceID
                ? tr.PurchaseInvoiceCode
                : tr.CreditNoteSupplierCode
            }}
          </vs-td>

          <vs-td
            :data="
              tr.PurchaseInvoiceID
                ? tr.PurchaseInvoiceBeforeValue
                : tr.CreditNoteSupplierBeforeValue
            "
          >
            {{
              tr.PurchaseInvoiceID
                ? priceFormat(tr.PurchaseInvoiceBeforeValue)
                : priceFormat(tr.CreditNoteSupplierBeforeValue)
            }}
          </vs-td>

          <vs-td :data="tr.DebitNoteCode">
            {{ tr.DebitNoteCode }}
          </vs-td>

          <vs-td :data="tr.DebitNoteBeforeValue">
            {{ priceFormat(tr.DebitNoteBeforeValue) }}
          </vs-td>

          <vs-td :data="tr.ClearingValue">
            {{ priceFormat(tr.ClearingValue) }}
          </vs-td>

          <vs-td
            :data="
              tr.PurchaseInvoiceID
                ? tr.PurchaseInvoiceAfterValue
                : tr.CreditNoteSupplierAfterValue
            "
          >
            {{
              tr.PurchaseInvoiceID
                ? priceFormat(tr.PurchaseInvoiceAfterValue)
                : priceFormat(tr.CreditNoteSupplierAfterValue)
            }}
          </vs-td>

          <vs-td :data="tr.DebitNoteAfterValue">
            {{ priceFormat(tr.DebitNoteAfterValue) }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  components: {
    Datepicker,
  },
  created() {},
  data() {
    return {
      search: "",
      schemaLines: [],
      informations: [],
    };
  },
  props: {
    selected: Object,
  },
  methods: {
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    dateFormat(value) {
      return moment(value).format("DD/MM/YYYY");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/debit-note-clearing/lines/" + this.selected.id)
        .then((result) => {
          this.$vs.loading.close();
          this.schemaLines = result.data.lines;
          this.informations = result.data.info;
        })
        .catch((e) => {
          console.log(e);
          this.$vs.loading.close();
        });
    },
    getAppliedTo(type) {
      if (type === 0) {
        return "Purchase Invoice";
      } else if (type === 1) {
        return "Credit Note Supplier";
      } else {
        return "Invalid";
      }
    },
    getTotal(id) {
      const total = this.informations.find((x) => x.id === id).total;
      return this.priceFormat(total);
    },
    generateTypeName() {
      if (!this.selected) {
        return;
      }
      if (this.selected.type === 0) {
        return "Invoice";
      } else {
        return "CN Supplier";
      }
    },
  },
  mounted() {
    this.getData();
  },
  computed: {},
  watch: {
    "selected.id"() {
      this.getData();
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}
</style>
