var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full ml-auto mr-auto"},[_c('vs-divider',{staticStyle:{"width":"100%"}},[_c('b',[_vm._v("Debit Note Apply Schema")])]),(_vm.schemaLines.length > 0)?_c('vs-table',{attrs:{"data":_vm.schemaLines},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',{attrs:{"data":indextr + 1}},[_vm._v(" "+_vm._s(indextr + 1)+" ")]),_c('vs-td',{attrs:{"data":tr.PurchaseInvoiceID
              ? tr.PurchaseInvoiceCode
              : tr.CreditNoteSupplierCode}},[_vm._v(" "+_vm._s(tr.PurchaseInvoiceID ? tr.PurchaseInvoiceCode : tr.CreditNoteSupplierCode)+" ")]),_c('vs-td',{attrs:{"data":tr.PurchaseInvoiceID
              ? tr.PurchaseInvoiceBeforeValue
              : tr.CreditNoteSupplierBeforeValue}},[_vm._v(" "+_vm._s(tr.PurchaseInvoiceID ? _vm.priceFormat(tr.PurchaseInvoiceBeforeValue) : _vm.priceFormat(tr.CreditNoteSupplierBeforeValue))+" ")]),_c('vs-td',{attrs:{"data":tr.DebitNoteCode}},[_vm._v(" "+_vm._s(tr.DebitNoteCode)+" ")]),_c('vs-td',{attrs:{"data":tr.DebitNoteBeforeValue}},[_vm._v(" "+_vm._s(_vm.priceFormat(tr.DebitNoteBeforeValue))+" ")]),_c('vs-td',{attrs:{"data":tr.ClearingValue}},[_vm._v(" "+_vm._s(_vm.priceFormat(tr.ClearingValue))+" ")]),_c('vs-td',{attrs:{"data":tr.PurchaseInvoiceID
              ? tr.PurchaseInvoiceAfterValue
              : tr.CreditNoteSupplierAfterValue}},[_vm._v(" "+_vm._s(tr.PurchaseInvoiceID ? _vm.priceFormat(tr.PurchaseInvoiceAfterValue) : _vm.priceFormat(tr.CreditNoteSupplierAfterValue))+" ")]),_c('vs-td',{attrs:{"data":tr.DebitNoteAfterValue}},[_vm._v(" "+_vm._s(_vm.priceFormat(tr.DebitNoteAfterValue))+" ")])],1)})}}],null,false,314117736)},[_c('template',{slot:"thead"},[_c('vs-th',[_vm._v(" # ")]),_c('vs-th',[_vm._v(" "+_vm._s(_vm.generateTypeName())+" ")]),_c('vs-th',[_vm._v(" "+_vm._s(_vm.generateTypeName())+" Remaining Before ")]),_c('vs-th',[_vm._v(" Debit Note ")]),_c('vs-th',[_vm._v(" Debit Note Remaining Before ")]),_c('vs-th',[_vm._v(" Paid ")]),_c('vs-th',[_vm._v(" "+_vm._s(_vm.generateTypeName())+" Remaining After ")]),_c('vs-th',[_vm._v(" Debit Note Remaining After ")])],1)],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }